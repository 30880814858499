form {
  &.is-application {
    .form-block {
      margin: 1rem 0 2rem 0;

      span.select-title {
        display: block;
        margin-bottom: 10px;
      }

      .bank-acc {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        margin-top: 10px;

        .prefix {
          width: 220px;
        }

        span {
          margin-bottom: 0.5rem;
        }
      }
    }

    p {
      margin: 1rem 0;
    }

    ul {
      padding-left: 1rem;
      margin-bottom: 2rem;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    div.buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 2rem;
      margin: 2rem 0;
    }

    div.button {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 750px) {
      width: 100%;

      .form-wrapper {
        width: 100%;
      }
    }
  }
  &.is-psd {
    button {
      margin-top: 1rem;
    }

    .form-block {
      margin: 1rem 0 2rem 0;

      p {
        margin-bottom: 1rem;
        font-size: 0.9rem;
      }

      span.select-title {
        display: block;
        margin-bottom: 10px;
      }

      .bank-acc {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;

        .prefix {
          width: 220px;
        }

        span {
          margin-bottom: 0.5rem;
        }
      }
    }

    @media (max-width: 600px) {
      & > div:first-of-type {
        padding: 0 !important;
      }
    }
  }
}
