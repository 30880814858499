@import '../../../sass/LineSeparator.scss';

.progress-wrapper {
  margin: 0 auto;
  // padding-top: 1.5rem;
  width: 100%;
  // border-top: 1px solid #a2a2a2;

  .info {
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #9276e3;
    font-weight: 700;
    font-size: 0.8rem;

    .line-separator {
      min-width: 1rem;
    }

    a {
      color: #9276e3;
    }

    .active {
      color: #00dec4;
      font-weight: 700;
    }

    .active ~ span {
      color: #838383;
      font-weight: 300;
    }
  }

  &.overview {
    font-size: 0.85rem;

    .info {
      padding: 0.6rem 1rem;
    }

    .line-separator {
      min-width: 1rem;
    }
  }
}

@media (max-width: 900px) {
  .progress-wrapper {
    margin-bottom: 1rem;

    .line-separator {
      display: none;
    }

    .info {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

// @media (max-width: 400px) {
//   .progress-wrapper {
//     .info {
//       padding: 0.5rem;
//       font-size: 0.9rem;
//     }
//   }
// }

@media (max-width: 450px) {
  .progress-wrapper {
    display: none;
    .info {
      span:not(.active) {
        display: none;
      }
    }
  }
}

// @media (max-width: 380px) {
//   .progress-wrapper {
//     display: none;
//   }
// }
