/* pie */

.pie-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: auto;
  cursor: default;
  margin-bottom: auto;
  // margin-top: 2.5rem;
}

.pie-wrapper > span {
  font-weight: 600;
  color: #4d6379;
  margin-top: 0.8rem;
}

@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 20px;
  --c: #00dec4;
  --w: 160px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-weight: bold;
  font-family: sans-serif;
  // color: var(--c);
  // font-weight: 800;
  // font-size: 2.4rem;
  background: #e5e5e5;
  border-radius: var(--w);
}

.pie div {
  display: block;
  background: #f8f8fb;
  position: absolute;
  left: var(--b);
  right: var(--b);
  bottom: var(--b);
  top: var(--b);
  text-align: center;
  border-radius: 80px;
  // line-height: calc(var(--w) - 2 * var(--b));
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  color: #4d6379;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: var(--c);
    font-weight: 800;
    font-size: 1.2rem;
  }
}

.pie:before,
.pie:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.pie-wrapper {
  &.table {
    flex-direction: row;
    gap: 0.5rem;

    > span {
      color: #00dec4;
      margin: 0;
      font-weight: 800;
    }
  }
}

.animate {
  animation: p 1s 0.5s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0;
  }
}

/* .pie */
