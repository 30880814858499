/* chart-wrapper */

.chart-wrapper {
  // margin: 3rem 0.8rem 1.6rem 0.8rem;
  margin: 3rem 0.8rem 6rem 0.8rem;
  position: relative;

  &.hidden-months {
    margin: 3rem 0.8rem 1.6rem 0.8rem;
  }
}

.chart-wrapper .chart-base {
  background: #e9e9e9;
  border-radius: 16px;
  height: 200px;
}

.chart-wrapper .chart-base .no-data {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  border: 0;
  line-height: 200px;
}

.chart-wrapper .chart-base .y-axis {
  position: absolute;
  left: -3.2em;
  border-left: 1px solid #d3d3d3;
  height: 224px;
  padding: 0.8em;
}

.chart-wrapper .chart-base .y-axis span {
  position: absolute;
  bottom: 0;
  font-weight: 400;
  font-size: 0.6rem;
  color: #4d6379;
  text-transform: uppercase;
}

.chart-wrapper .chart-base hr {
  position: absolute;
  bottom: 0;
  margin: 0;
  border: 1px solid #d3d3d3;
  width: 100%;
}

.chart-wrapper .chart-base .chart-one {
  width: calc(70% - 16px);
  position: absolute;
  bottom: 0;
  left: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  left: 0;
  padding-left: 16px;
}

.chart-wrapper .chart-base .chart-two {
  background: rgb(248, 248, 251, 0.55);
  border-radius: 28px;
  width: calc(30% - 24px);
  // height: 235px;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
}

.chart-wrapper .chart-base .chart-one {
  div {
    height: 160px;
    background: #c2c2c2;
    // width: 12px;
    flex-grow: 1;
    margin: 0 4px;
    cursor: default;
    position: relative;

    &.zero {
      background: transparent;
    }

    .month {
      font-size: 0.6rem;
      text-align: center;
      position: absolute;
      bottom: -28px;
      left: 0;
      right: 0;
    }
  }

  &.hidden-months div .month {
    display: none;
  }
}

.chart-wrapper .chart-base .chart-two p {
  font-family: 'Nunito', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  color: #838383;
  position: absolute;
  top: 0.4rem;
  left: 0;
  right: 0;
  text-align: center;
}

.chart-wrapper .chart-base .chart-two {
  div {
    height: 160px;
    background: #ff89b5;
    // width: 12px;
    flex-grow: 1;
    // margin: 4px;
    margin: 0 4px;
    cursor: default;
    position: relative;

    &.zero {
      background: transparent;
    }

    .month {
      font-size: 0.6rem;
      text-align: center;
      position: absolute;
      bottom: -28px;
      left: 0;
      right: 0;
    }
  }

  &.hidden-months div .month {
    display: none;
  }
}

.chart-wrapper .chart-base .chart-one .x-axis,
.chart-wrapper .chart-base .chart-two .x-axis {
  position: absolute;
  // bottom: -1.6rem;
  bottom: -3.5rem;
  height: 0.8rem;
  // width: 95%;
  width: 100%;
  left: 0;
  text-align: center;
  color: #4d6379;
  border-bottom: 1px solid #d3d3d3;
  background: transparent;
  font-weight: 400;
  font-size: 0.6rem;
  width: calc(100% - 16px);
  left: 16px;
}

.chart-wrapper .chart-base .chart-two .x-axis {
  width: calc(100% - 32px);
}

// .chart-wrapper .chart-base .chart-one.hidden-months .x-axis,
// .chart-wrapper .chart-base .chart-two.hidden-months .x-axis {
//   bottom: -1.6rem;
// }

.chart-wrapper.hidden-months {
  .chart-one .x-axis,
  .chart-two .x-axis {
    bottom: -1.6rem;
  }
}

@keyframes lineInserted {
  from {
    height: 0;
  }
}

.chart-wrapper .chart-base .chart-one div:not(.x-axis),
.chart-wrapper .chart-base .chart-two div:not(.x-axis) {
  animation-duration: 0.3s;
  animation-name: lineInserted;
  transition: height 0.3s;
}

/* .chart-wrapper */

@media (max-width: 900px) {
  .chart-wrapper .chart-base .chart-one {
    display: none;
  }

  .chart-wrapper .chart-base .chart-two {
    width: 100%;
    right: 0;
  }
}
