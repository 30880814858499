.application-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
}

.application-body-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 60%;
  margin: 0 auto;
  margin-top: 5rem;

  img {
    width: 200px;
  }
}

.body-wrapper {
  h1 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 1rem;
    text-align: left;

    span {
      background: linear-gradient(90.16deg, #47acd9 1.9%, #746ae4 44.12%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.progress-wrapper {
  padding-top: 1.5rem;
  border-top: 1px solid #a2a2a2;
}

.body {
  padding: 0 1rem;

  form {
    // width: 60%;

    .form-wrapper {
      width: 60%;
    }

    .form-wrapper div {
      min-width: 240px;
    }

    .consent-given {
      a {
        margin: 0;
        font-size: 1rem;
        color: #43afd8;
        text-decoration: underline;
      }
    }
  }

  .form-block {
    margin: 1rem 0 2rem 0;

    // span {
    //   display: block;
    // }

    span.select-title {
      display: block;
      margin-bottom: 10px;
    }

    .bank-acc {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;

      .prefix {
        width: 220px;
      }

      span {
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    margin: 1rem 0;
  }

  ul {
    padding-left: 1rem;
    margin-bottom: 2rem;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  div.consent-given {
    margin-top: 1rem;
  }

  div.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    margin: 2rem 0;
  }

  div.button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    // text-align: center;
  }
}

.summary-wrapper {
  position: absolute;
  top: 12rem;
  left: 0rem;
  color: #4d6379;

  > span {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: block;
    text-align: center;
  }
}

.summary-info-wrapper {
  align-items: center;
  border: 2px solid #c1c1c1;
  border-radius: 10px;
  text-align: center;
  padding: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.summary-info {
  padding: 1.5rem 1.5rem 1.5rem 4rem;
  display: flex;
  flex-direction: column;

  .financing {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #898989;
    border-radius: 10px;
    padding: 1rem;

    > span {
      font-weight: 600;
      font-size: 1rem;
      color: #898989;

      &.value {
        font-weight: 800;
        font-size: 1.5rem;
      }
    }
  }

  .title {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  > .value {
    border: 2px solid #969696;
    border-radius: 10px;
    font-weight: 800;
    color: #4b4b4b;
    padding: 0.5rem;
  }

  .total {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
    color: #fff;
    border: 2px solid #afafaf;
    background: #afafaf;

    > span {
      font-weight: 600;
      font-size: 1rem;

      &.value {
        font-weight: 800;
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  .summary-wrapper {
    display: none;
  }

  .application-body-wrapper {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .progress-wrapper {
    // display: none;

    .line-separator {
      display: none;
    }

    .info {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

@media (max-width: 750px) {
  .body {
    form {
      width: 100%;

      .form-wrapper {
        width: 100%;
      }
    }
  }

  .application-body-wrapper {
    margin-top: 2rem;
  }

  .application-body-wrapper img {
    display: none;
  }
}

@media (max-width: 450px) {
  .progress-wrapper {
    display: none;
    .info {
      padding: 0.5rem;
      font-size: 0.9rem;
    }
  }
}
