.hg-flows-container > .hotglue-w-MuiBox-root {
  gap: 0;
}

.hg-flows-container {
  .hg-flow-title,
  .hg-flow-subtitle,
  hr {
    display: none;
  }
}

.hg-connectors {
  flex-direction: row;
  flex-wrap: wrap !important;
  gap: 1.5rem !important;

  .hg-connector-card {
    display: flex;
    flex-direction: column;
    width: 250px;
    margin-bottom: 1rem;
    border-radius: 15px;
    position: relative;

    .hg-connector-name {
      font-family: 'Inter', sans-serif;
      font-size: 1rem;
      color: #838383;
    }

    .hg-connector-settings {
      top: 5px;
      // background-color: #ffffff;

      // svg {
      //   fill: #838383;
      // }

      // &:hover {
      //   background-color: #ffffff;

      //   svg {
      //     fill: #838383;
      //   }
      // }
    }

    &.hg-connector-card-linked {
      .hg-connector-settings {
        background-color: #ffffff;

        &:hover {
          background-color: rgb(217, 217, 217);
          // border: 0;

          // svg {
          //   fill: #ffffff;
          // }
        }
      }
    }

    .hg-connector-linked {
      display: block;
      position: absolute;
      // width: calc(100% + 2rem);
      width: 100%;
      text-align: center;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 0.7rem;
      color: #ffffff;
      background: linear-gradient(
        265.75deg,
        rgba(0, 222, 196, 0.9) -1.14%,
        rgba(162, 107, 244, 0.9) 101.09%
      );
      border-radius: 8px;
      margin: 0;

      p {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: initial;
        text-transform: lowercase;
        font-family: 'Inter', sans-serif;
        margin: 0;
        padding: 0;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

// @media (max-width: 1200px) {
//   .hg-connectors {
//     margin: 0 auto;
//     margin-top: 1rem;
//     width: 100%;
//     justify-content: center;
//   }
// }

@media (max-width: 1200px) {
  .hg-connectors {
    justify-content: center;
  }
}
