.content-wrapper,
.commissions-wrapper {
  margin: 2.4rem auto 2.4rem auto;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  // max-width: 750px;
  width: 100%;
}

a.pdf img {
  width: 20px;
}

.table {
  max-width: 750px;
  min-width: 750px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .commissions-wrapper {
    margin: 0;
    display: block;
  }
}

@media (max-width: 800px) {
  .table {
    max-width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 450px) {
  .content-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
