@media (max-width: 600px) {
  .dialog {
    h2 {
      padding: 4rem 2rem 0rem 2rem;
    }

    form > div:first-of-type {
      padding: 0.5rem 2rem 0.5rem 2rem !important;
    }

    form > div:nth-of-type(2) {
      padding: 0.5rem 2rem 1rem 2rem !important;
      justify-content: center;

      button {
        margin: 0;
      }
    }

    .MuiPaper-root {
      border-radius: none;
    }
  }
}
