.dialog-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // width: 95%;
  margin: 1rem;

  img {
    width: 150px;
  }
}

.form-block {
  margin: 1rem 0 2rem 0;

  p {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }

  span.select-title {
    display: block;
    margin-bottom: 10px;
  }

  .bank-acc {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;

    .prefix {
      width: 220px;
    }

    span {
      margin-bottom: 0.5rem;
    }
  }
}

.connection-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .connection {
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 8px;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid rgb(240, 240, 240);

    .header {
      display: flex;
      flex-direction: column;
      // gap: 8px;

      h2 {
        font-size: 1rem;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 700;
      }

      span {
        font-size: 0.8rem;

        &.valid-to {
          font-style: italic;
        }
      }
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      background-color: transparent;
      outline: 0px;
      margin: 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      text-align: center;
      flex: 0 0 auto;
      font-size: 1.71429rem;
      padding: 8px;
      border-radius: 50%;
      overflow: visible;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      width: 32px;
      height: 32px;
      color: rgb(100, 97, 107);
      border: 1px solid rgb(217, 217, 217);
      // margin-top: auto;
      // top: 5px;

      svg {
        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentcolor;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1rem;
      }

      &:hover {
        color: rgb(250, 41, 83);
        background-color: rgba(250, 41, 83, 0.55);
      }
    }
  }
}

button {
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .dialog-wrapper {
    margin-top: 0rem;
    width: auto;

    img {
      display: none;
    }

    form > div:first-of-type {
      padding: 0 !important;
    }
  }
}
