.dialog-wrapper {
  // padding: 1rem 5rem 2rem;
  display: flex;
  flex-direction: column;

  p > span {
    display: block;
    margin-bottom: 2rem;
    // text-align: justify;
  }
}
