/* filter-wrapper */

.filter-wrapper {
  display: flex;
  justify-content: flex-start;
  color: #838383;
  margin: 0.8rem 0.8rem 0 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #838383;
  font-family: 'Nunito', sans-serif;
}

.filter-wrapper .partners,
.filter-wrapper .branches,
.filter-wrapper .calendar {
  display: flex;
  align-items: center;
}

.filter-wrapper .branches,
.filter-wrapper .calendar {
  margin-left: 2rem;
}

.filter-wrapper .partners label,
.filter-wrapper .branches label,
.filter-wrapper .calendar label {
  font-weight: 600;
  font-size: 1.1rem;
}

.filter-wrapper .calendar span {
  font-weight: 600;
  font-size: 0.85rem;
  border: 1px solid #939393;
  border-radius: 5px;
  padding: 0.4rem;
  margin-left: 0.8rem;
}

.filter-wrapper .client {
  font-size: 0.75rem;
  text-align: right;
  margin-left: auto;
}

.filter-wrapper .client img {
  // width: 80%;
  height: 2.25rem;
  width: auto;
}

/* .filter-wrapper */

@media (max-width: 900px) {
  .filter-wrapper .calendar {
    display: none;
  }
}

@media (max-width: 550px) {
  // .filter-wrapper {
  //   display: none;
  // }

  // .filter-wrapper .partners label,
  // .filter-wrapper .branches label {
  //   display: none;
  // }

  .filter-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .filter-wrapper .client {
    display: none;
  }

  .filter-wrapper .branches {
    margin-left: 1rem;
  }
}

@media (max-width: 450px) {
  .filter-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 400px) {
  .filter-wrapper .partners label {
    display: none;
  }
}
