/* filter-wrapper */

.filter-wrapper {
  display: flex;
  justify-content: space-between;
  color: #838383;
  // margin: 0.8rem 0.8rem 1rem 0.8rem;
  margin: 0.8rem auto 1rem auto;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #838383;
  font-family: 'Inter', sans-serif;
  // max-width: 1300px;
}

.filter-wrapper .calendar {
  display: flex;
  align-items: center;
}

.filter-wrapper .calendar label {
  font-weight: 500;
  font-size: 1.1rem;
}

.filter-wrapper .client-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-wrapper .client-wrapper .client {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.5rem;
}

.filter-wrapper .client-wrapper .client span {
  display: block;
  font-size: 0.75rem;
  text-align: right;
  width: max-content;
  color: #4d6379;
  font-size: 0.8rem;
  font-weight: 400;

  &.title {
    font-size: 0.95rem;
    font-weight: 600;
  }
}

.filter-wrapper .client-wrapper img {
  width: 80%;
}

.filter-wrapper .client {
  font-size: 0.75rem;
  text-align: right;
  margin-left: auto;
}

.filter-wrapper .client img {
  // width: 80%;
  height: 2.25rem;
  width: auto;
}

/* .filter-wrapper */

// @media (max-width: 550px) {
//   .filter-wrapper {
//     display: none;
//   }
// }

@media (max-width: 450px) {
  .filter-wrapper {
    margin: 0.8rem;
  }
}
