.page-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  margin-top: 120px;

  img {
    position: absolute;
    width: 200px;
    margin-left: -680px;
    margin-top: 80px;
  }

  .card-wrapper {
    max-width: 450px;
  }

  h1 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 900px) {
  .page-wrapper {
    margin-top: 54px;
    flex-direction: column-reverse;

    img {
      position: relative;
      margin-left: initial;
      margin-top: 2rem;
    }
  }
}

@media (max-width: 600px) {
  .page-wrapper {
    margin-top: 0.8rem;
  }
}
