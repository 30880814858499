@import '../../sass/variables';

header {
  width: 100%;
  height: 7rem;
  background: linear-gradient(90.38deg, #8e79ee -18.02%, #18cdcb 122.86%);
  margin: 0 auto;
  position: relative;
  font-family: 'Nunito', sans-serif;
}

header.no-header-info {
  height: 5rem;
}

// .logout {
//   position: absolute;
//   right: 0;
//   top: 0;
//   padding: 1.5rem;
// }

button.menu {
  display: none;
  // position: absolute;
  // left: 0;
  // padding: 1.5rem;
  // bottom: 0;
  // padding-bottom: 0;
}

// .logout img {
//   width: 34.4px;
//   height: 34.4px;
// }

.logout span.logout-text {
  font-size: 0.75rem;
  font-weight: 500;
}

.header-info {
  position: absolute;
  bottom: 0;
  height: 6.4rem;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  left: 6rem;
  color: #ffffff;
}

.header-info svg {
  right: 100%;
  position: absolute;
  height: 6.4rem;
  width: 4.5rem;
  transform: scale3d(-1, 1, 1);
  fill: rgba(255, 255, 255, 0.15);
}

.header-info svg:last-child {
  right: auto;
  left: 100%;
  transform: none;
}

.header-info p {
  margin: 0.5rem 1rem 0 1rem;
  font-size: 1.5rem;
}

.header-info p:first-child {
  margin-left: 2.5rem;
}

.header-info p:last-child {
  margin-right: 2.5rem;
}

.header-info p span {
  font-weight: 700;
}

.application {
  display: none;
  position: absolute;
  bottom: 0;
  height: 2.4rem;
  background: rgba(255, 255, 255, 0.15);
  // display: flex;
  justify-content: center;
  color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;

  p span {
    font-weight: 700;
  }
}

.hamburger-menu .header-info p:first-child,
.hamburger-menu .header-info p:last-child {
  margin-left: 1rem;
}

/* nav */

nav {
  text-align: center;
  display: block;
  position: absolute;
  bottom: 0;
  left: 4rem;
}

nav ul {
  position: relative;
  display: flex;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
}

nav ul li {
  position: relative;
  display: block;
  text-align: center;
  flex: 1;
  margin-left: 0.65rem;
  margin-right: 2.35rem;
}

nav ul li:first-child {
  margin-left: 0px;
}

nav ul li a {
  position: relative;
  display: block;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

nav ul li a svg {
  position: absolute;
  left: 100%;
  margin: 0;
  height: 100%;
  // width: 2.35rem;
  width: 2.375rem;
  fill: #ffffff;
}

nav ul li a svg:nth-child(2),
nav ul li a svg:nth-child(3) {
  right: 100%;
  left: auto;
  /* transform: scale3d(-1, 1, 1); */
}

nav ul li:first-child a svg:nth-child(2) {
  transform: scale3d(-1, 1, 1);
}

nav ul li a svg:nth-child(3) {
  display: none;
  transform: scale3d(-1, 1, 1);
}

nav ul li a span {
  color: #838383;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  display: block;
  overflow: hidden;
  background: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem 1.25rem 0 1.25rem;
}

nav ul li:not(:first-child).active {
  margin-left: 0px;
}

nav ul li.active a span {
  color: #9e6ee5;
  font-size: 1.75rem;
  line-height: 2.4rem;
  font-weight: 700;
  background: #ffffff !important;
}

nav ul li.active a svg {
  fill: #ffffff !important;
}

nav ul li:not(:first-child).active a svg:nth-child(2) {
  display: none;
}

nav ul li.active a svg:nth-child(3) {
  display: block;
}

nav ul li.l1 a svg {
  fill: rgb(255, 255, 255, 0.92);
}

nav ul li.l1 a span {
  background: rgb(255, 255, 255, 0.92);
}

nav ul li.l2 a svg {
  fill: rgb(255, 255, 255, 0.84);
}

nav ul li.l2 a span {
  background: rgb(255, 255, 255, 0.84);
}

nav ul li.l3 a svg {
  fill: rgb(255, 255, 255, 0.77);
}

nav ul li.l3 a span {
  background: rgb(255, 255, 255, 0.77);
}

nav ul li.l4 a svg {
  fill: rgb(255, 255, 255, 0.69);
}

nav ul li.l4 a span {
  background: rgb(255, 255, 255, 0.69);
}

nav ul li.l5 a svg {
  fill: rgb(255, 255, 255, 0.56);
}

nav ul li.l5 a span {
  background: rgb(255, 255, 255, 0.56);
}

nav ul li a:hover {
  text-decoration: none;
}

nav ul li a:hover span {
  color: #9e6ee5;
  background: #ffffff;
}

nav ul li a:hover svg {
  fill: #ffffff;
}

/* .nav */

.user {
  position: absolute;
  color: #ffffff;
  // margin-right: 20px;
  right: 0;
  top: 10px;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .name {
    font-weight: 600;
    max-width: 10rem;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.no-header-info {
  .user {
    flex-direction: row-reverse;
  }

  .name {
    padding-bottom: 5px;
    margin-right: 0;
  }
}

.hamburger-menu {
  width: 100%;
  height: calc(100% - 50px);
  background: linear-gradient(
    196.48deg,
    rgba(0, 222, 196, 0.85) -4.5%,
    rgba(162, 107, 244, 0.85) 98.86%
  );
  font-family: 'Nunito', sans-serif;
}

.nav-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff;
}

.navigation {
  display: flex;
  flex-direction: column;
  height: 100%;

  ul {
    position: relative;
    display: flex;
    margin: 2rem;
    padding: 0;
    list-style: none;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;

    li {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #d1d1d1;

      a {
        position: relative;
        display: block;
        overflow: visible;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        font-size: 24px;
        color: #ffffff;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.contacts {
  margin-top: auto;

  ul li {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 700;
    align-items: center;

    h3 {
      position: relative;
      display: block;
      overflow: visible;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
      margin: 0;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
    }
    .large-img {
      width: 28px;
      height: 28px;
      margin-right: 0.5rem;
    }
  }
}

.activeCustomer,
.activeCustomer svg {
  color: #9e6ee5 !important;
  font-weight: 600 !important;
}

@media (max-width: 1230px) {
  .header-info p {
    font-size: 1.3rem;
  }

  nav ul li a span {
    font-size: 1.2rem;
  }

  nav ul li.active a span {
    font-size: 1.45rem;
  }
}

@media (max-width: 1120px) {
  .header-info p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1050px) {
  header {
    height: 6.5rem;
  }

  .header-info {
    position: initial;
    justify-content: center;
    left: auto;
    height: auto;
    padding-bottom: 0.5rem;
  }

  .header-info svg {
    display: none;
  }

  button.menu {
    display: inline-flex;
  }

  button.logout {
    bottom: 0;
    top: initial;
    padding: 5px;
  }

  .logout span.logout-text {
    display: none;
  }

  nav {
    left: 8rem;
  }

  // nav ul li:not(:first-child) {
  //   display: none;
  // }

  nav ul li {
    display: none;
  }

  nav ul li.active {
    display: block;
  }

  // .logout {
  //   bottom: 0;
  //   padding-bottom: 0;
  //   top: initial;
  // }

  .user {
    bottom: 0;
    top: initial;
    padding: 5px;
    bottom: 0;
    padding-bottom: 0;
    top: initial;

    .name {
      display: none;
    }
  }

  .application {
    display: flex;
  }
}

svg.user-circle path {
  stroke: #ffffff;
}

@media (max-width: 900px) {
  .header-info p:nth-of-type(2) {
    display: none;
  }
}

@media (max-width: 700px) {
  .header-info p:nth-of-type(4) {
    display: none;
  }
}

@media (max-width: 550px) {
  nav {
    margin: 0 auto;
    width: 50%;
    left: 0;
    right: calc(-2.35rem / 2);
    padding-left: calc(2.35rem / 2);
  }

  nav ul li.active a span {
    font-size: 1.2rem;
  }
}

@media (max-width: 450px) {
  .header-info {
    justify-content: space-between;
  }

  .header-info p {
    font-size: 1rem;
  }

  nav ul li.active a span {
    padding: 0.5rem 0rem 0 0rem;
  }
}

@media (max-width: 400px) {
  .header-info p {
    font-size: 0.875rem;
  }

  .menu,
  .logout {
    padding: 1rem;
  }
}
