* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #f5f5f5;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #838383;
}

a {
  text-decoration: none;
  outline: none;
  color: #747474;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: 'Nunito', sans-serif;
}

h2 {
  font-weight: 600;
  font-size: 1.1rem;
  color: #838383;
  margin-bottom: 0.5rem;
}

h3 {
  color: #4d6379;
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 1rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

img {
  width: 100%;
  height: auto;
}

a.button {
  background: #00dec4;
  border-radius: 10px;
  border: 0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.56rem 2.16rem;
  cursor: pointer;
}

a.button:hover {
  background: #02e6cc;
}

a.button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.8rem;
}

a.button:hover {
  text-decoration: none;
}

.hidden {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.gradient {
  align-items: center;
  background-clip: content-box, border-box;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #48abda, #9c6ff3);
  background-origin: border-box;
  border: 3px solid transparent;
  border-radius: 10px;
  text-align: center;
  padding: 0rem;
}

.gradient > * {
  padding: 1rem;
}

.gradient.info {
  border-bottom-left-radius: initial;
  width: 100%;
}

.gradient.info span {
  color: #6e727e;
  background: none;
  -webkit-text-fill-color: initial;
}

.gradient-bg {
  background: linear-gradient(268.66deg, #a26bf4 -12%, #00dec4 118.95%);
  border-radius: 16px 16px 16px 0px;
  width: fit-content;
  padding: 0.8rem 1.6rem;
}

.gradient-bg.fixed-size {
  min-width: 750px;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-bg h1 {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.6rem;
}

.gradient-bg h2 {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5;
}

.content-wrapper {
  width: 100%;
  /* padding: 1.5rem; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-grow: 1;
  /* min-height: 100vh; */
  flex-direction: column;
  position: relative;
  max-width: 1680px;
}

.indicative-offer {
  /* border-radius: 10px; */
  /* border: 2px solid rgb(17, 153, 216); */
  background-color: #fff;
}

main {
  background: #ffffff;
  padding: 0 2.4rem;
  /* min-height: calc(100vh - 8rem); */
  min-height: calc(100vh - 10rem); /* header + footer */
  /* position: relative; */
  padding-bottom: 3rem; /* fixed footer */
}

main.no-header-info {
  /* min-height: calc(100vh - 5rem); */
  min-height: calc(100vh - 8rem); /* header + footer */
}

main.not-authenticated {
  background: transparent;
  min-height: 100vh;
}

@media (max-width: 900px) {
  main {
    padding: 0 30px !important;
  }
}

@media (max-width: 800px) {
  .gradient-bg.fixed-size {
    min-width: auto;
    min-height: auto;
  }
}

@media (max-width: 600px) {
  main {
    min-height: auto;
  }
}

@media (max-width: 650px) {
  .gradient-bg {
    padding: 0.8rem 1rem;
  }

  .gradient-bg h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 450px) {
  main,
  main.no-header-info {
    padding: 0 !important;
  }
}

@media (max-width: 700px) {
  #root h5 {
    font-size: 1rem;
  }
  #root p {
    font-size: 0.813rem;
  }
}
