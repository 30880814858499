.offer {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  position: relative;

  .offer-body {
    background: #f9f9f9;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 1rem;

    img {
      width: 180px;
      position: absolute;
      margin-left: -750px;
      top: -60px;
    }

    .body-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 590px;

      h2 {
        font-size: 1.85rem;
        font-family: "Inter", sans-serif;
        background: linear-gradient(87.47deg, #c74fff -26.69%, #00dec4 99.76%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .body-info {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        span {
          text-align: left;
          font-weight: 400;
          font-size: 0.95rem;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: baseline;

    h2 {
      flex-grow: 1;
      text-align: left;
    }

    .alt-action {
      color: #999999;
      text-align: right;
      text-decoration: none;
      border-bottom: 1px solid;
      flex-flow: row;
      height: fit-content;
      display: flex;
      margin: 0.5rem 0;
      align-items: center;

      &:hover {
        color: #9e6ee5;
      }
    }

    .alt-action-icon {
      padding-right: 0.25rem;
      // font-size: 1rem;

      // svg {
      //   font-size: 1rem;
      // }
    }
  }

  .footer {
    max-width: 650px;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  button span {
    font-size: 1rem;
  }

  p {
    margin-bottom: 1rem;
    font-weight: 700;
    color: #838383;
  }
}

@media (max-width: 900px) {
  .offer {
    .offer-body {
      img {
        display: none;
      }
    }
  }
}

@media (max-width: 680px) {
  .offer {
    .offer-body {
      .body-info-wrapper {
        align-items: center;

        h2 {
          font-size: 1.5rem;
        }

        .body-info {
          flex-direction: column;
          align-items: center;

          span {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .offer {
    margin-top: 5rem;
    margin-bottom: 3.5rem;

    button span {
      font-size: 1rem;
    }
  }
}

@media (max-width: 450px) {
  .offer {
    margin-left: 1rem;
    margin-right: 1rem;

    .offer-body {
      .body-info-wrapper {
        align-items: center;

        h2 {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .offer {
    display: none;
  }
}
