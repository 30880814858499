button.linkButton {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: initial;
  color: initial;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: initial;
  cursor: pointer;
}

button.linkButton:hover {
  background: none;
}

button.linkButton:disabled,
button.linkButton:focus:disabled,
button.linkButton:hover:disabled,
button.linkButton:active:disabled {
  cursor: not-allowed;
}
