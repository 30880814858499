.card {
  background: linear-gradient(
    90.19deg,
    #fafafa 0.15%,
    #ffffff 51.02%,
    #fbfbfb 99.82%
  );
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  padding: 1.6rem;

  a {
    display: block;
    margin-top: 1rem;
    text-align: right;
    font-weight: 300;
    font-size: 0.8rem;
    color: #747474;
  }
}
