/* qr-code */

.qr-code {
  border-left: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
}

.qr-code h3 {
  color: #4d6379;
  font-weight: 600;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  max-width: 8rem;
}

.qr-code .qr-info {
  font-weight: 500;
  font-size: 0.75rem;
  color: #4d6379;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.8rem 0 0.8rem 0;
}

/* .qr-code */

@media (max-width: 700px) {
  .qr-code {
    min-width: 100% !important;
    justify-content: center !important;
    border-left: 0 !important;
    border-top: 1px solid #d3d3d3 !important;
    border-bottom: 1px solid #d3d3d3 !important;
  }
}

@media (max-width: 450px) {
  .qr-code {
    flex-direction: column;
    align-items: center;
  }

  .qr-code .qr-info h3 {
    max-width: initial;
  }
}
