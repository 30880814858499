/* my-financing-wrapper */

.my-financing-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
}

.my-financing-wrapper .payment-wrapper {
  flex-direction: column;
  border-right: 1px solid #d3d3d3;
  padding-right: 2rem;
  flex-basis: 50%;
}

.my-financing-wrapper .history-wrapper {
  flex-basis: 50%;
  padding-left: 2rem;
}

.my-financing-wrapper .current-financing {
  width: auto;
  justify-content: center;
}

.financing-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  flex-basis: 50%;
}

/* .my-financing-wrapper */

@media (max-width: 1400px) {
  .my-financing-wrapper {
    // flex-direction: column;
    flex-direction: column-reverse;
    // margin-bottom: 0;
    margin-bottom: 2.4rem;
  }

  .my-financing-wrapper .payment-wrapper {
    flex-basis: 100%;
    padding-right: 0;
    border-right: 0;
  }

  .my-financing-wrapper .history-wrapper {
    flex-basis: 100%;
    padding-left: 0;
    // margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .financing-info-wrapper {
    margin-top: 1rem;
  }
}

@media (max-width: 700px) {
  .financing-info-wrapper {
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem !important;
  }
}
