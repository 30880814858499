@import '../../sass/LineSeparator.scss';

/* current-financing */

.current-financing {
  background: #f8f8fb;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  padding: 1.6rem;
  align-items: center;
  gap: 1.6rem;
  width: fit-content;
}

.current-financing.center {
  width: auto;
  justify-content: center;
}

.current-financing .payment {
  display: flex;
  flex-direction: column;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    h3 {
      margin-bottom: 0;
    }

    .paid-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: #00dec4;
      font-weight: 600;
      font-size: 1rem;
      margin-right: 10px;

      span.value {
        font-weight: 600;
      }
    }
  }
}

.current-financing .header {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  font-weight: 400;
  font-size: 0.9375rem;
  border: 3px solid transparent;
}

.current-financing .pay-list {
  gap: 0.32rem;
  padding: 0.4rem;
  font-weight: 600;
  font-size: 1rem;
  // color: #4D6379;
  display: flex;
  flex-direction: column;
  border: 3px solid transparent;
  border-radius: 8px;
  // width: fit-content;

  .overdue-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    text-align: left;
    padding: 1rem 0;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 0.5rem;

    span {
      color: #ff4f90;
      font-weight: 500;
      font-size: 0.85rem;

      &.info {
        font-weight: 700;
        padding: 0.5rem 0;
      }

      a {
        color: #ff4f90;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.current-financing .pay-list.paid {
  border-color: #00dec4;
  background: #fdfdfd;
  color: #747474;
}

.current-financing .pay-list .line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  .line-wrapper {
    display: flex;
  }

  .date,
  .text {
    width: 6.25rem;
    display: inline-block;
  }

  .text {
    width: 7.5rem;
  }

  .detail-info {
    display: none;
  }
}

// .current-financing .pay-list:not(.paid) span.date {
//   font-weight: 300;
// }

.current-financing .pay-list .value {
  color: #747474;
  font-size: 1rem;
  width: 6.875rem;
  text-align: right;
}

// .current-financing .pay-list:not(.paid) .value {
//   font-weight: 800;
// }

.current-financing.detail {
  background-color: transparent;
  padding: 2rem 0;
  border-top: 1px solid #d3d3d3;
  // border-bottom: 1px solid #d3d3d3;
  width: auto;
  border-radius: 0;
  margin-bottom: 2rem;
  justify-content: center;

  .header {
    display: flex;

    .date,
    .paid,
    .value {
      display: inline-block;
      width: 6.25rem;
    }

    .date {
      width: 12.5rem;
    }

    .value {
      width: 6.875rem;
      text-align: right;
    }
  }

  .pay-list .line {
    .detail-info {
      display: block;
    }
  }
}

.current-financing .prolongation {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 0.2rem;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: 0.8rem;

  span {
    font-weight: 300;
    font-size: 0.75rem;
    text-align: center;
  }
}

.more-information-button {
  margin-right: 0 !important;
}

/* .current-financing */

@media (max-width: 1400px) {
  .current-financing {
    width: auto;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .current-financing {
    flex-direction: column;
  }

  .current-financing.detail {
    padding-top: 1rem;
    padding-bottom: 0;
    border-top: 0;
  }

  .current-financing .payment {
    width: 100%;
  }

  .current-financing .pay-list {
    width: auto;
  }

  .line-separator {
    min-width: initial;
  }
}

@media (max-width: 550px) {
  .current-financing.detail .header span.paid,
  .current-financing .pay-list .line .text {
    display: none;
  }

  .current-financing .pay-list .line {
    .detail-info {
      display: none !important;
    }
  }

  .current-financing.detail .header .date {
    width: auto;
  }

  // .current-financing .header span:nth-child(2) {
  //   display: none;
  // }
}

@media (max-width: 450px) {
  .current-financing {
    padding: 1rem;
  }
}
