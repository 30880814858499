.section {
  display: flex;
  flex-direction: row;
  margin: 0.8rem auto;
  // justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #838383;
  position: relative;
  justify-content: flex-start;
  gap: 3rem;
  // max-width: 1300px;

  &.no-border {
    border: 1px solid transparent;
  }

  h2 {
    margin-bottom: 2.5rem;
  }
}

.info-box {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
  min-width: 220px;

  &.no-border {
    border: 1px solid transparent;
    padding: 1rem 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    &.highlight {
      border: 2px solid #ff4f90;
      border-radius: 10px;
      padding: 0.5rem;
    }

    span {
      font-weight: 400;
      font-size: 0.95rem;
      color: #747474;
      display: block;
      text-align: center;

      &.bold {
        font-weight: 600;
        display: inline;
      }

      &.rounded {
        border: 2px solid #dadada;
        border-radius: 10px;
        padding: 0.5rem;
        font-size: 1.1rem;
        font-weight: 800;

        &.alt {
          border: 3px solid #00dec4;
        }

        &.alt3 {
          border: none;
          background: linear-gradient(
            102.38deg,
            #00dec4 -77.89%,
            #a26bf4 122.01%
          );
          border-radius: 10px;
          color: #ffffff;
          // padding: 1rem 2rem;
          padding: 0.7rem;
        }

        &.alt5 {
          align-items: center;
          background-clip: content-box, border-box;
          background-image: linear-gradient(#fff, #fff),
            linear-gradient(90deg, #48abda, #9c6ff3);
          background-origin: border-box;
          border: 3px solid transparent;
          border-radius: 10px;
          text-align: center;
          padding: 0 !important;

          span {
            display: block;
            padding: 0.7rem 2rem;
            color: #747474;
            font-size: 1.1rem;
            font-weight: 800;
            background: none;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
          }
        }
      }

      &.highlight {
        // padding: 0.5rem;
        font-size: 1.1rem;
        font-weight: 800;
        color: #ff4f90;
      }
    }
  }
}

.chart-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  // width: 100%;
  margin-bottom: 1rem;
  // margin-right: auto;
  // margin-right: 2rem;
  // width: 100%;
  width: calc(100% - (220px + 3rem));

  // &.pair {
  //   margin-left: 2rem;
  // }

  .y-axis {
    border-right: 1px solid #d3d3d3;
    height: 100%;
    // left: -3.2em;
    // padding: 0.8em;
    position: relative;
    // width: 2rem;

    span {
      font-weight: 400;
      font-size: 0.6rem;
      color: #4d6379;
      transform-origin: bottom right;
      right: 0;
      bottom: 0;
      transform: rotate(-90deg) translateX(100%);
      position: absolute;
    }
  }

  .x-axis {
    background: transparent;
    border-top: 1px solid #d3d3d3;
    bottom: -1.3rem;
    color: #4d6379;
    font-size: 0.6rem;
    font-weight: 400;
    height: 0.8rem;
    position: absolute;
    text-align: left;
    // width: 95%;
    left: 0.5rem;
    width: calc(100% - 0.5rem);
    // width: 100%;

    span {
      &.highlight {
        color: #ff4f90;
        font-weight: 600;
      }
    }
  }

  .chart {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    margin-left: 0.5rem;
    position: relative;
    width: 100%;
    overflow-x: auto;

    .line-wrapper {
      font-size: 0.625rem;
      text-align: center;

      .lines {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        gap: 0.25rem;
        margin-bottom: 0.2rem;

        .line {
          display: flex;
          align-items: flex-end;
        }

        .paid,
        .expected {
          background: linear-gradient(
            102.38deg,
            #00dec4 -77.89%,
            #a26bf4 122.01%
          );
          cursor: default;
          height: 160px;
          // margin: 0 7px;
          width: 16px;

          animation-duration: 0.3s;
          animation-name: lineInserted;
          transition: height 0.3s;
        }

        .expected {
          background: #dadada;
        }

        .zero {
          background: none;
        }
      }
    }
  }

  &.has-scroll {
    .x-axis {
      bottom: -1rem;
    }

    .chart {
      .line-wrapper {
        margin-bottom: 0.8rem;
      }
    }
  }
}

@keyframes lineInserted {
  from {
    height: 0;
  }
}

@media (max-width: 1200px) {
  .section {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .chart-wrapper {
    width: 100%;

    .y-axis {
      height: auto;
    }
  }
}

@media (max-width: 450px) {
  .section {
    margin: 2.4rem 0.8rem 1rem 0.8rem;
  }
}
