.dialog-wrapper {
  margin-top: 1.5rem;
  // width: 552px;
  height: 500px;
}

@media (max-width: 600px) {
  .dialog-wrapper {
    margin-top: 0rem;
    width: auto;
  }
}
