/* revenue-wrapper */

.revenue-wrapper {
  display: flex;
  justify-content: space-between;
}

.revenue-wrapper .revenue {
  display: flex;
  justify-content: flex-start;
  color: #747474;
  text-align: center;
  font-weight: 600;
}

.revenue-wrapper .revenue .period {
  background: #e3e3e3;
  border-radius: 10px;
  margin: 0.8rem;
  padding: 1.6rem 0.8rem;
}

.revenue-wrapper .revenue .daily {
  margin: 0.8rem;
  color: #4d6379;
  font-size: 0.95rem;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.revenue-wrapper .revenue .daily .sub {
  font-size: 0.75rem;
  font-weight: 400;
}

.revenue-wrapper .revenue .value {
  font-size: 1.1rem;
  font-weight: 800;
}

.revenue-wrapper .revenue .daily .value {
  font-weight: 800;
  border: 3px solid #b8b8b8;
  border-radius: 10px;
  padding: 0.32rem;
  margin-top: 0.8rem;
}

.revenue-wrapper .revenue.next {
  color: #ffffff;
}

.revenue-wrapper .revenue.next .period {
  background: #ff4f90;
}

.revenue-wrapper .revenue.next .daily .value {
  border: 3px solid #ff4f90;
}

/* .revenue-wrapper */

.financing-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.current-financing-wrapper,
.financing-info-wrapper {
  flex-basis: 50%;
}

.financing-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
}

.finished-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 60%;
  margin: 0 auto;
  margin-top: 5rem;

  img {
    width: 200px;
    margin-top: 5rem;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
      font-weight: 400;
      font-size: 1.6rem;
      color: #919191;
      text-align: left;

      span {
        background: linear-gradient(90.16deg, #47acd9 1.9%, #746ae4 44.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }
  }
}

.offer-mobile {
  display: none;
  // display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem 0.8rem 1rem 0.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #838383;

  > span {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 1400px) {
  .financing-wrapper {
    flex-wrap: wrap;
    // margin-bottom: 0;
    margin-bottom: 2.4rem;
  }

  .current-financing-wrapper,
  .financing-info-wrapper {
    flex-basis: 100%;
  }

  .financing-info-wrapper {
    margin-top: 1rem;
  }
}

@media (max-width: 1050px) {
  .finished-wrapper {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .finished-wrapper {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2rem;

    img {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 700px) {
  .financing-info-wrapper {
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem !important;
  }
}

@media (max-width: 900px) {
  .revenue-wrapper {
    display: block;
    justify-content: center;
  }

  .revenue-wrapper .revenue {
    display: flex;
    justify-content: space-between;
  }

  .revenue-wrapper .revenue.next {
    display: none;
  }
}

@media (max-width: 550px) {
  .revenue-wrapper {
    margin-top: 1rem;
  }
}

@media (max-width: 450px) {
  .revenue-wrapper .revenue {
    flex-direction: column;
    align-items: center;
  }

  .finished-wrapper {
    padding: 1rem;
  }
}

@media (max-width: 420px) {
  .offer-mobile {
    display: flex;
  }
}
