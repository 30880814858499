button {
  background: #00dec4;
  border-radius: 8px;
  border: 0;
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 0.448rem 1.728rem;
  cursor: pointer;
}

button:hover {
  background: #02e6cc;
}

.button:focus {
  outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  cursor: not-allowed;
}

button.gradient {
  align-items: center;
  background-clip: content-box, border-box;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #48abda, #9c6ff3);
  background-origin: border-box;
  border: 3px solid transparent;
  border-radius: 8px;
  text-align: center;
  padding: 0 !important;
}

button.gradient:hover {
  background-clip: content-box, border-box;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #48abda, #9c6ff3);
  background-origin: border-box;
}

button.gradient span {
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.8rem 2.4rem;
  background: linear-gradient(90.16deg, #47acd9 1.9%, #746ae4 44.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}

button.gradient.reverse {
  background-image: linear-gradient(90deg, #48abda, #9c6ff3),
    linear-gradient(90deg, #48abda, #9c6ff3);

  span {
    color: #fff;
    -webkit-text-fill-color: #fff;
  }
}

button.gradient.partner {
  width: fit-content;
  margin: 0 auto;
}

button.gradient.dialog {
  width: fit-content;
  margin-right: auto;

  span {
    padding: 0.5rem 2.5rem;
  }
}

button.gradient.partner span {
  font-weight: 700;
  font-size: 0.8rem;
  padding: 0.56rem 0.64rem;
}

button.gradient.form span {
  font-size: 1rem;
  padding: 0.64rem 2.4rem;
}

button.application {
  background: #ff4f90;
  padding: 0.7rem 3rem;
}

button.overview-banner {
  background: #ff4f90;
  min-width: 220px;
  max-width: 220px;

  &:hover {
    background: #ff3781;
  }
}

button.overview-banner-mobile {
  background: #ff4f90;
  width: 100%;
  padding: 0.8rem 3rem;

  &:hover {
    background: #ff3781;
  }
}

@media (max-width: 570px) {
  button.application {
    padding: 0.7rem 2rem;
  }
}

@media (max-width: 600px) {
  button {
    width: fit-content;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  button.application {
    padding: 0.7rem 1rem;
  }
}
