@import '../../sass/LineSeparator.scss';

.next-payment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: #00dec4;
  padding: 2rem 0;
  width: 100%;
  font-weight: 700;
  flex-wrap: wrap;
}

.next-payment span.line-separator::before {
  border-bottom: 2px solid #00dec4;
}

.next-payment.overdue span {
  color: #fcae00;
}

.next-payment.overdue span.line-separator::before {
  border-bottom: 2px solid #fcae00;
}

.next-payment.partly {
  padding-bottom: 0.5rem;
}

.partly-paid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 60%;
  margin-left: auto;
  padding-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;

  .value {
    font-weight: 600;
  }
}

.next-payment .info {
  flex-basis: 100%;
  text-align: right;
  font-weight: 700;
  font-size: 0.75rem;
  color: #ff4f90;
}

.overdue-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  margin-left: auto;
  text-align: left;
  padding-bottom: 2rem;
  border-top: 1px solid #d3d3d3;

  span {
    color: #ff4f90;
    font-weight: 500;
    font-size: 0.85rem;

    &.info {
      font-weight: 700;
      padding: 0.5rem 0;
    }

    a {
      color: #ff4f90;
      text-decoration: underline;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1400px) {
  .overdue-info {
    width: 50%;
  }
}

@media (max-width: 1300px) {
  .next-payment {
    width: 70%;
    margin: 0 auto;
  }

  .partly-paid {
    width: 70%;
    max-width: 70%;
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .next-payment {
    width: 100%;
  }

  .partly-paid {
    width: fit-content;
    max-width: fit-content;
    margin: initial;
    margin-left: auto;
  }
}

@media (max-width: 600px) {
  // .next-payment,
  // .overdue-info,
  // .partly-paid {
  //   display: none;
  // }

  .next-payment span.date,
  .next-payment span.date + span.line-separator {
    display: none;
  }

  .overdue-info {
    width: auto;
  }

  .partly-paid {
    width: auto;
    max-width: initial;
  }
}
