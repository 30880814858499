@import '../../sass/LineSeparator.scss';

.history-table {
  background: #f8f8fb;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 0.5rem;
  color: #4d6379;
  font-weight: 600;
  font-size: 0.9375rem;
}

.history-table .line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.history-table .line span.end-date {
  display: none;
  color: #fca600;
}

.history-table .line.done span.end-date {
  display: none;
  color: #09c7bb;
}

.history-table .line span.status {
  flex: 1;
}

.history-table .line span.status {
  font-weight: 800;
  font-size: 1.25rem;
  color: #00dec4;
  text-align: right;
}

.history-table .line span.status.in-progress {
  color: #fca600;
}

.history-table .line span.detail {
  text-align: right;
  // width: 110px;
}

.history-table .line span a {
  color: #4d6379;
  font-weight: 600;
  font-size: 0.9375rem;
}

.history-table .line span a:hover {
  text-decoration: underline;
}

@media (max-width: 1540px) {
  .history-table .line span.value,
  .history-table .line span.value + span.line-separator {
    display: none;
  }

  .history-table .line span.status {
    flex: 0;
  }
}

@media (max-width: 1400px) {
  .history-table .line span.value,
  .history-table .line span.value + span.line-separator {
    display: block;
  }
}

@media (max-width: 800px) {
  .history-table .line span.value,
  .history-table .line span.value + span.line-separator {
    display: none;
  }
}

@media (max-width: 600px) {
  .history-table .line span.date,
  .history-table .line span.status {
    display: none;
  }

  // .history-table .line span.in-progress {
  //   display: block;
  // }

  .history-table .line span.end-date {
    display: block !important;
  }
}
