.settings-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}

.settings-wrapper h2 {
  // font-weight: 700;
  font-size: 1.2rem;
  color: #4d6379;
  font-family: 'Inter', sans-serif;
}

.personal-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 3rem;

  button {
    border: 1px solid #838383;
    background: transparent;
    color: #838383;
    width: fit-content;
    margin-left: 50%;
    margin-top: 2rem;
    font-weight: 400;

    &:hover {
      border: 1px solid #000000;
      color: #000000;
      background: transparent;
    }
  }

  .line {
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;

    .label {
      // flex: 1;
      border-right: 1px solid #d3d3d3;
      min-width: 190px;
      margin-right: 1rem;
      padding: 0.3rem 0;

      span {
        font-size: 0.75rem;
      }
    }

    .value {
      // font-weight: 700;
      font-weight: 600;
      padding: 0.3rem 0;
    }
  }
}

.notifications-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 1rem;

  .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1rem;

    .label {
      // flex: 1;
      margin-right: 2rem;
      padding: 0.3rem 0;

      span {
        font-size: 0.75rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .settings-wrapper {
    // margin-bottom: 0;
    margin-bottom: 2.4rem;
  }
}

@media (max-width: 450px) {
  .personal-wrapper {
    width: initial;

    .line {
      // justify-content: space-between;

      .label {
        min-width: 130px;
        width: 130px;
        border: none;
        font-size: 1rem;

        span {
          display: block;
        }
      }
    }

    button {
      margin: 0 auto;
      margin-top: 2rem;
    }
  }

  .notifications-wrapper {
    margin-bottom: 2rem;

    .line {
      .label {
        font-size: 1rem;
      }
    }
  }
}
