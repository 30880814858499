/* payment-table */

.payment-table {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.payment-table .payment-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.payment-table .payment-box:not(:last-child) {
  border-right: 1px solid #d3d3d3;
}

.payment-table .payment-box span {
  display: block;
  color: #4d6379;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

.payment-table .payment-box span.title {
  margin-bottom: 0.5rem;
}

.payment-table .payment-box span.value {
  color: #ff4f90;
  text-align: center;
  margin-top: auto;

  &.pdf {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
}

.payment-table .payment-box span.value img {
  width: 30px;
}

.payment-table .payment-box:first-child span.value {
  font-size: 1.5rem;
  font-weight: 800;
}

/* .payment-table */

@media (max-width: 1440px) {
  .payment-table .payment-box span {
    font-size: 1rem;
  }

  .payment-table .payment-box:first-child span.value {
    font-size: 1.2rem;
  }
}

@media (max-width: 700px) {
  .payment-table {
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }

  .payment-table .payment-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .payment-table .payment-box span.title {
    margin-bottom: 0;
  }

  .payment-table .payment-box:not(:last-child) {
    border: 0;
    border-bottom: 1px solid #d3d3d3;
  }

  .payment-table .payment-box {
    padding-bottom: 0.5rem;
  }
}
