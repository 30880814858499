.line-separator {
  flex: auto;
  position: relative;
  min-width: 4rem;
}

.line-separator::before {
  position: absolute;
  content: '';
  left: 0%;
  top: 0%;
  width: 100%;
  height: 50%;
  border-bottom: 1px solid #747474;
  right: 0;
}
