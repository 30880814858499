.financing-detail-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}

.financing-detail-wrapper > a {
  color: #bcbcbc;
  margin-bottom: 2rem;
}

.detail-wrapper {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
}

.detail-wrapper .payment-wrapper {
  flex-direction: column;
  border-right: 1px solid #d3d3d3;
  padding-right: 2rem;
  flex-basis: 50%;
}

.detail-wrapper .history-wrapper {
  flex-basis: 50%;
  padding-left: 2rem;
  margin-top: 1rem;

  h2 {
    text-align: center;
  }
}

.financing-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  flex-basis: 50%;
  padding: 6.4px;
  border: 3px solid #00dec4;
  flex-grow: 1;
  border-radius: 0.5rem;

  &.detail {
    justify-content: space-between;
  }
}

.financing-detail-wrapper.no-history {
  flex-direction: row;

  > a {
    margin-bottom: 2rem;
    margin-right: 4rem;
    border-right: 1px solid #d3d3d3;
    padding-right: 2rem;
  }

  .detail-wrapper .payment-wrapper {
    border: none;
    flex-basis: 50%;
    padding-right: 0;
    flex-basis: auto;
  }
}

@media (max-width: 1400px) {
  .financing-detail-wrapper {
    // margin-bottom: 0;
    margin-bottom: 2.4rem;
  }

  .detail-wrapper {
    flex-direction: column;
  }

  .detail-wrapper .payment-wrapper {
    flex-basis: 100%;
    padding-right: 0;
    border-right: 0;
  }

  .detail-wrapper .history-wrapper {
    flex-basis: 100%;
    padding-left: 0;
    // margin-top: 1rem;
  }
}

@media (max-width: 1050px) {
  .financing-detail-wrapper.no-history {
    flex-direction: column;

    > a {
      margin: 0;
      margin-bottom: 2rem;
      padding: 0;
      border: none;
    }

    .detail-wrapper .payment-wrapper {
      border: none;
      flex-basis: 50%;
      padding-right: 0;
      flex-basis: auto;
    }
  }
}

@media (max-width: 700px) {
  .financing-info-wrapper {
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem !important;
    border: none;
  }
}
