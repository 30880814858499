.offer-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;

  h1 {
    color: #4d6379;
    text-align: center;
  }

  h2 {
    color: #4d6379;
    text-align: center;
    font-size: 1.2rem;

    &.overview {
      font-weight: 800;
      font-size: 1.5rem;
    }
  }

  &.indicative {
    margin: 0.8rem 0 0 0;

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1rem;
      margin-top: 0rem;
    }
  }
}

.slider {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.8rem;
}

.products-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0;

  button {
    font-weight: 500;
    font-size: 0.875rem;
    color: #959595;
    border: 3px solid #b6b6b6;
    border-radius: 12px;
    padding: 0.7rem 1.5rem;
    background: transparent;
    width: 150px;
    cursor: auto;
    margin: 0;

    &.offerd {
      border: 3px solid #ff4f90;
      color: #5b5b5b;
      cursor: pointer;

      &.selected,
      &:hover {
        background: #ff4f90;
        color: #fff;
      }
      &.selected {
        font-weight: 700;
      }
    }
  }
}
.postponed-wrapper {
  text-align: center;
  margin: 1rem auto 1rem auto;
  width: 460px;
}
.overview-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  gap: 1rem;
  margin-top: 1.5rem;
  color: #4d6379;
  width: fit-content;

  .line-wrapper {
    font-weight: 300;
    font-size: 1.125rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;

    span.text {
      font-weight: 700;
    }

    span.text.custom {
      font-weight: 300;
    }

    hr {
      flex: auto;
      position: relative;
      min-width: 4rem;
      border: 0;
      height: 1px;
      background: linear-gradient(90deg, #47acd9, #746ae4);
    }

    span.date {
      display: inline-block;
      min-width: 100px;
      font-weight: 700;
      color: #4d6379;
      font-size: 20px;
    }

    span.value {
      font-weight: 800;
      font-size: 1.25rem;
      background: linear-gradient(90.16deg, #47acd9 1.9%, #746ae4 44.12%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.summary-wrapper {
  position: absolute;
  top: 12rem;
  right: 0rem;
  color: #4d6379;

  > span {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: block;
    text-align: center;
    padding: 0 4rem 0 1.5rem;
  }
}

.summary-info-wrapper {
  align-items: center;
  background-clip: content-box, border-box;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(90deg, #48abda, #9c6ff3);
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  text-align: center;
  padding: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.summary-info {
  padding: 1.5rem 4rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;

  .financing {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #ff4f90;
    border-radius: 10px;
    padding: 1rem;

    > span {
      font-weight: 600;
      font-size: 1rem;
      color: #898989;

      &.value {
        font-weight: 800;
        font-size: 1.5rem;
        color: #ff4f90;
      }
    }
  }

  .fee {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    > .value {
      align-items: center;
      background-clip: content-box, border-box;
      background-image: linear-gradient(#fff, #fff),
        linear-gradient(90deg, #48abda, #9c6ff3);
      background-origin: border-box;
      border: 2px solid transparent;
      border-radius: 10px;
      text-align: center;
      padding: 0 !important;

      span {
        display: block;
        padding: 0.5rem;
        color: #4b4b4b;
        font-weight: 800;
        font-size: 1rem;
        background: none;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
      }
    }
  }

  .total {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
    color: #fff;
    border: 2px solid #ff4f90;
    background: #ff4f90;

    > span {
      font-weight: 600;
      font-size: 1rem;

      &.value {
        font-weight: 800;
        font-size: 1.5rem;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 0 2rem 1rem 2rem;
  width: fit-content;
  gap: 1.5rem;
}

.no-offer {
  width: 60%;
  margin: 0 auto;

  h2 {
    margin: 2rem 0;
  }

  form {
    margin-top: 2rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    .button {
      margin-top: 1rem;
      text-align: center;
    }
  }
}

.no-offer-submited {
  max-width: 600px;
  margin: 3rem auto;
}

.overlay-form-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
  align-items: flex-start;
  // min-height: 100vh;
  // margin-top: -50px;
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  margin-top: 3rem;
}

.overlay-form-wrapper img {
  // position: absolute;
  width: 200px;
  // margin-left: -900px;
  // margin-top: 80px;
  // margin-left: -750px;
  margin-top: 20px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;

  .button {
    // padding: 10rem 0;
    padding: 3rem 0;
  }
}

.form-wrapper form {
  margin-top: 1.6rem;
  padding: 2.4rem 10rem 2.4rem 10rem;
  width: fit-content;
}

/* form */

.form {
  background: linear-gradient(
    90.19deg,
    #fafafa 0.15%,
    #ffffff 51.02%,
    #fbfbfb 99.82%
  );
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .line {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;

    .actions {
      margin-left: auto;
    }
  }
}

/* .form */

.indicative {
  .slider {
    width: 100%;
    margin: 0.5rem auto 0 auto;
  }

  .offer-wrapper {
    .how-long {
      margin: 0.2rem 0;
    }
  }

  .products-wrapper {
    margin: 0;

    button {
      font-size: 0.9rem;
      padding: 0.6rem 1.7rem;
    }
  }

  .summary-wrapper {
    position: initial;
    margin-top: 1rem;

    > span {
      padding: 0;
    }

    .summary-info-wrapper {
      border: 2px solid transparent;
      border-radius: 10px;
      // width: fit-content;
      margin: 0 auto;
      border: 2px solid #00dec4;
      border-radius: 5px;
      background-image: none;
      width: 700px;

      .summary-info {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1rem 0.5rem;
        gap: 1rem;

        .financing,
        .fee,
        .total {
          border: none;
          border-radius: 0;
          padding: 0;
          margin-top: 0;
          background: none;
          flex-grow: 1;
          flex-basis: 0;
          text-align: center;

          > span {
            margin-top: 0rem;
            font-weight: 400;
            font-size: 1rem;
            color: #4d6379;

            &.value {
              font-weight: 800;
              font-size: 1.2rem;
              color: #ff4f90;
              border: 2px solid #ff4f90;
              border-radius: 5px;
              padding: 0.5rem 1.5rem !important;
              margin-top: 0.15rem;
              background-image: none;
            }
          }
        }

        .fee > span.value {
          border: 2px solid #4d6379;
          color: #4d6379;
        }

        .total > span.value {
          background: #ff4f90;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 1230px) {
  .offer-wrapper {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    width: fit-content;
  }

  .no-offer {
    form {
      margin-top: 2rem;
      width: 100%;
    }
  }

  .buttons {
    margin-top: 0rem;
    padding: 0;

    button > span {
      font-size: 1rem;
      padding: 0.7rem 2rem;
    }
  }
}

@media (max-width: 1100px) {
  .overlay-form-wrapper {
    margin-top: 54px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .overlay-form-wrapper img {
    position: relative;
    margin-left: initial;
    margin-top: 2rem;
  }

  .form-wrapper {
    .button {
      // padding: 3rem 0;
      padding-bottom: 0rem;
    }
  }
}

@media (max-width: 1000px) {
  .offer-wrapper {
    //align-items: center;
    width: auto;
  }

  .summary-wrapper {
    position: initial;
    margin-top: 2rem;

    > span {
      padding: 0;
    }

    .summary-info-wrapper {
      border: 2px solid transparent;
      border-radius: 10px;

      .summary-info {
        padding: 1.5rem;
      }
    }
  }

  .indicative {
    .summary-wrapper {
      .summary-info-wrapper {
        width: auto;
      }
    }
  }
}

@media (max-width: 750px) {
  .products-wrapper {
    // flex-wrap: wrap;
    // justify-content: space-evenly;
    // width: 330px;
    margin: 1rem 0;

    button {
      font-size: 1rem;
      width: auto;
      padding: 0.7rem;
    }
  }

  .postponed-wrapper {
    width: 350px;
  }

  .progress-wrapper {
    width: auto;
  }

  h2.how-long {
    margin-top: 1rem;
  }
}

@media (max-width: 700px) {
  .no-offer {
    width: 90%;
  }

  .overlay-form-wrapper {
    margin-top: 0.8rem;
  }

  .form-wrapper form {
    padding: 1.6rem;
    width: 100%;

    .line {
      .actions {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .form-wrapper form {
    .line {
      .actions {
        margin: 0 auto;
      }
    }
  }

  .products-wrapper {
    flex-wrap: wrap;

    margin: 1rem 0 2rem 0;

    button {
      width: 155px;
    }
  }

  .offer-wrapper {
    &.indicative {
      margin: 0.5rem 0 0 0;

      h2.how-long {
        margin-top: 0.2rem 0;
      }

      .products-wrapper {
        flex-direction: row;
        flex-wrap: nowrap;

        button {
          // font-size: 0.8rem;
          // padding: 0.5rem;
          // width: auto;
          margin-top: 0;
          padding: 7px;
        }
      }

      .summary-wrapper {
        .summary-info-wrapper {
          .summary-info {
            // padding: 0.5rem;
            flex-direction: column;
          }
        }
      }

      .buttons {
        button > span {
          font-size: 1.1875rem;
          padding: 0.2rem 0;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .overview-table {
    .line-wrapper {
      font-size: 1rem;

      hr {
        min-width: 1.5rem;
      }

      span.date {
        min-width: 90px;
      }

      span.value {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 450px) {
  .offer-wrapper {
    h2 {
      font-size: 1rem;
    }
    &.indicative {
      margin: 0.5rem 0 0 0;

      h2.how-long {
        margin-top: 0.2rem 0;
      }

      .products-wrapper {
        flex-direction: row;
        flex-wrap: nowrap;

        button {
          font-size: 0.8rem;
          // padding: 0.5rem;
          // width: auto;
          // margin-top: 0;
          padding: 7px;
        }
      }

      .summary-wrapper {
        .summary-info-wrapper {
          .summary-info {
            // padding: 0.5rem;
            flex-direction: column;
          }
        }
      }

      .buttons {
        button > span {
          font-size: 1.1875rem;
          padding: 0.2rem 0;
        }
      }
    }
  }

  .postponed-wrapper {
    width: 200px;
  }

  h2.how-long {
    margin-top: 2rem;
  }
}

@media (max-width: 388px) {
  .products-wrapper {
    button {
      width: 130px;
    }
  }
}
