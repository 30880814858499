.input-wrapper {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}

.input-wrapper label {
  display: block;
  color: #8a8a8a;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.32rem;
}

.input-wrapper .input {
  position: relative;
  font-size: 1.2rem;
  background: linear-gradient(21deg, #10abff, #1beabd);
  padding: 3px;
  display: inline-block;
  border-radius: 10.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.input-wrapper .input *:not(span) {
  position: relative;
  display: inherit;
  border-radius: inherit;
  margin: 0;
  border: none;
  outline: none;
  padding: 0.64rem;
  width: 320px;
  color: #4d6379;
  font-size: 0.8rem;
}

.input-wrapper.invalid input {
  border-color: red;
  background: #fbdada;
}

@media (max-width: 600px) {
  .input-wrapper {
    width: 100%;
  }

  .input-wrapper .input {
    width: 100%;
  }

  .input-wrapper .input *:not(span) {
    width: 100%;
  }
}
