.connections-wrapper {
  // margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  margin: 2.4rem -2.4rem 2.4rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}

.info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    margin-bottom: 0;
    margin-right: 2rem;
    color: #747474;
  }

  p > span {
    font-weight: 700;
  }
}

.welcome-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
  margin: 0;

  img {
    width: 200px;
  }

  h2 {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.6rem;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.partners-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 3rem;
  width: 80%;

  .partner-box-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    // width: 80%;

    .partner-box {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 250px;
      margin-bottom: 1rem;
      align-items: flex-start;
      justify-content: flex-start;
      border: 1px solid #d9d9d9;
      border-radius: 15px;
      padding: 16px 16px 16px 24px;
      margin-bottom: 1rem;
      gap: 20px;
      min-height: 126px;

      p.active {
        display: none;
      }

      .partner {
        position: relative;
        display: flex;
        // flex-direction: column;
        flex-direction: row;
        align-items: center;
        // align-items: flex-start;
        // justify-content: flex-start;
        // border: 1px solid #d9d9d9;
        // border-radius: 15px;
        // padding: 1rem;
        // padding: 16px 16px 16px 24px;
        // height: 150px;
        // margin-bottom: 1rem;

        > span {
          margin-top: auto;
        }

        img {
          // width: 200px;
          max-width: 200px;
          max-height: 50px;
          // padding: 1rem 0;
        }

        p.active {
          display: none;
        }
      }

      &.active {
        > button {
          display: none;
        }

        p.active {
          display: block;
          position: absolute;
          // width: calc(100% + 2rem);
          width: 100%;
          text-align: center;
          left: 0;
          bottom: 0;
          right: 0;
          padding: 0.7rem;
          font-weight: 700;
          font-size: 1.6rem;
          color: #ffffff;
          background: linear-gradient(
            265.75deg,
            rgba(0, 222, 196, 0.9) -1.14%,
            rgba(162, 107, 244, 0.9) 101.09%
          );
          border-radius: 8px;
        }

        button {
          // display: none;
          background-color: #ffffff;

          &:hover {
            background-color: rgb(217, 217, 217);
          }
        }

        button.settings {
          display: inline-flex;
          font-size: inherit;
        }
      }

      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        background-color: transparent;
        outline: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-align: center;
        flex: 0 0 auto;
        font-size: 1.71429rem;
        padding: 8px;
        border-radius: 50%;
        overflow: visible;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 32px;
        height: 32px;
        color: rgb(100, 97, 107);
        border: 1px solid rgb(217, 217, 217);
        margin-top: auto;
        top: 5px;

        svg {
          user-select: none;
          width: 1em;
          height: 1em;
          display: inline-block;
          fill: currentcolor;
          flex-shrink: 0;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-size: 1rem;
        }

        &:hover {
          background-color: rgba(107, 114, 128, 0.55);
        }
      }

      button.settings {
        display: none;
      }

      .linked {
        display: block;
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0.7rem;
        color: #ffffff;
        background: linear-gradient(
          265.75deg,
          rgba(0, 222, 196, 0.9) -1.14%,
          rgba(162, 107, 244, 0.9) 101.09%
        );
        border-radius: 8px;
        margin: 0;

        p {
          font-size: 1.6rem;
          font-weight: 700;
          line-height: initial;
          // text-transform: lowercase;
          font-family: 'Inter', sans-serif;
          margin: 0;
          padding: 0;
        }
      }

      &.psd {
        .partner {
          img {
            max-width: 50px;
            max-height: 50px;
          }

          span {
            margin-left: 1rem;
            margin-top: inherit;
          }
        }
      }
    }
  }

  .new,
  .how-to-mobile {
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    padding: 1.5rem 2rem;
    max-width: 1070px;
    width: 100%;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      > button {
        display: inline;
        margin: 0;
      }

      .title {
        font-weight: 700;
        font-size: 1.75rem;
        background: linear-gradient(90.16deg, #47acd9 1.9%, #746ae4 44.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    button {
      display: none;
    }

    .text {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .how-to-mobile {
    display: none;
  }
}

.how-to {
  // position: absolute;
  position: sticky;
  top: 5%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 2rem;
  width: 300px;
  gap: 1.5rem;
  color: #747474;
  right: 0;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  .title {
    font-weight: 700;
    font-size: 1.75rem;
    background: linear-gradient(90.16deg, #47acd9 1.9%, #746ae4 44.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li > a {
      color: #747474;
      line-height: 1.8rem;
      cursor: pointer;
    }
  }
}

@media (max-width: 1400px) {
  .connections-wrapper {
    // margin-bottom: 0;
    margin-bottom: 2.4rem;
  }
}

@media (max-width: 1200px) {
  .connections-wrapper {
    margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  }

  .partners-wrapper {
    // margin: 0 auto;
    // margin-top: 1rem;
    width: 100%;

    .partner-box-wrapper {
      justify-content: center;
    }

    .how-to-mobile {
      display: block;
    }
  }

  .how-to {
    // position: relative;
    // border: 0;
    // width: auto;
    display: none;
  }

  .welcome-wrapper {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .how-to-mobile {
    margin-bottom: 1rem;
  }
}

@media (max-width: 950px) {
  .new {
    .title-wrapper {
      .title > span {
        display: none;
      }
    }
  }
}

@media (max-width: 800px) {
  .info-wrapper {
    flex-direction: column;

    h2 {
      margin: 0 0 1rem 0;
    }
  }

  .welcome-wrapper {
    width: 100%;

    img {
      display: none;
    }

    h2 {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 700px) {
  .new,
  .how-to-mobile {
    text-align: center;

    .title-wrapper {
      justify-content: center !important;

      // .title > span {
      //   display: none;
      // }

      > button {
        display: none !important;
      }
    }

    > button {
      display: block !important;
      margin: 1rem auto !important;
    }
  }
}
