.error-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  margin-top: 120px;

  img {
    position: absolute;
    width: 200px;
    margin-left: -820px;
    margin-top: 50px;
  }

  .info {
    max-width: 600px;

    a {
      display: block;
      margin-top: 1rem;
      text-align: center;
    }
  }
}

@media (max-width: 1000px) {
  .error-wrapper {
    margin-top: 54px;
    flex-direction: column-reverse;

    img {
      position: relative;
      margin-left: initial;
      margin-top: 2rem;
    }
  }
}

@media (max-width: 600px) {
  .error-wrapper {
    margin-top: 0.8rem;
  }
}
