.filter-wrapper {
  display: flex;
  justify-content: space-between;
  color: #838383;
  margin: 2rem 0.8rem 0 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #838383;
  font-family: 'Inter', sans-serif;

  .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3.5rem;

    label {
      font-weight: 500;
    }

    .criterion {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    button {
      padding: 0.5rem 1.5rem !important;
      color: #838383;
      font-family: 'Inter', sans-serif;
      background: transparent;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: 400;

      &:hover {
        border: 1px solid #212121;
      }
    }
  }
}

.content-wrapper {
  margin: 2.4rem 0.8rem 2.4rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}

@media (max-width: 700px) {
  .filter-wrapper {
    .filter {
      gap: 1rem;
    }
  }
}

@media (max-width: 550px) {
  .filter-wrapper {
    justify-content: center;

    .filter {
      .criterion {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;
      }
    }
  }
}
