@import '../../sass/variables';

footer {
  height: 3rem;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.9rem;
  width: 100%;
  bottom: 0;
  max-width: 1680px;
  z-index: 1201;

  &.transparent {
    background: transparent;
  }

  &.fixed {
    position: fixed;
  }

  button.language {
    background: none;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 18px;
    margin-right: 1rem;

    img {
      width: 24px;
      height: 18px;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 5rem;

    img {
      width: 150px;
      height: auto;
    }
  }

  .contact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 700;

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.4rem;
    }

    .large-img {
      width: 28px;
      height: 28px;
      margin-right: 0.4rem;
    }
  }
}

@media (max-width: 1310px) {
  footer {
    span {
      display: none;
    }
  }
}

// To fix the issues with footer, when screen gets smaller
@media (max-width: 830px) {
  footer {
    justify-content: center;

    .contact-info {
      display: none;
    }

    .logo {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
